import React from "react";
import styled from "../lib/styled";
import { Link } from "gatsby";
const NewsArchiveLink: React.FC = () => {
  return <NewsArchives to="/newsarchives">News Archives</NewsArchives>;
};

const NewsArchives = styled(Link)`
  text-align: center;
  display: flex;
  justify-content: center;
  font-size: 12px;
`;

export default NewsArchiveLink;
