import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image"
import * as React from "react";
import ReactHtmlParser from "react-html-parser";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import styled from "../lib/styled";
import { ICarouselItem } from "../pages/index";
import { presets } from "../lib/presets";

const SliderContainer = styled.div`
  height: 100%;
  width: 100%;
  overflow: hidden;
  max-height: 500px;
  ${presets.mq.max.xl} {
    min-height: 300px;
  }
  border: 1px solid ${(props) => props.theme.color.accent};

  div {
    height: 100%;
    min-height: 0;
    min-width: 0;
  }
`;

const Slide = styled.div`
  position: relative;
  overflow: hidden;
  height: 100%;
  outline: none;
  cursor: ew-resize;
  ${presets.mq.max.xl} {
    min-height: 300px;
  }
  div {
    height: auto;
  }
`;
const Title = styled.h2`
  font-family: "Bebas Neue Regular";
  margin: 0;
  margin-bottom: 10px;
  font-size: 3rem;
  line-height: 1;
  color: #d8d7d7;
  text-shadow: 0 0 15px rgba(0, 0, 0, 1);
`;
const DotsContainer = styled.div`
  position: absolute;
  height: auto !important;
  right: 30px;
  bottom: 30px;
  display: flex;

  li {
    list-style: none;
    font-size: 0;
    height: 15px;
    width: 15px;
    background: whitesmoke;
    margin: 5px;
    border: 2px solid ${(props) => props.theme.color.white};
    * {
      display: none;
    }
    &.slick-active {
      background-color: ${(props) => props.theme.color.accent};
    }
  }
`;

const SlideImage = styled.div`
  /* position: absolute; */
  width: 100%;
  z-index: -1;
  top: 0;
  display: block;
  left: 0;
  height: 100% !important;
`;

const GradientContainer = styled.div`
  max-width: 250px;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 0 30px 25px;
  position: absolute;
  bottom: 0;
`;

const StyledLink = styled(Link)`
  font-family: "Bebas Neue";
  padding: 6px 10px;
  background: ${(props) => props.theme.color.accentDark};
  color: white;
  font-size: 1.2rem;
`;

const Carousel = ({ articles }: { articles: ICarouselItem[] }) => {
  if (articles) {
    return (
      <SliderContainer>
        <Slider
          dots={true}
          arrows={false}
          autoplay={true}
          infinite={true}
          speed={500}
          slidesToShow={1}
          slidesToScroll={1}
          autoplaySpeed={6000}
          appendDots={(dots) => <DotsContainer>{dots}</DotsContainer>}
        >
          {articles.map((article, i) => {
            const image =
              article.coverImage?.localFile?.childImageSharp?.gatsbyImageData;

            const title = article.coverText;

            return (
              <Slide key={i}>
                <SlideImage id={`image-${i}`}>
                  {image && (
                    <GatsbyImage
                      alt={title}
                      image={image}
                      style={{ display: "block", height: "100%" }}
                    />
                  )}
                </SlideImage>
                <GradientContainer>
                  <Title>{ReactHtmlParser(title)}</Title>
                  <div>
                    <StyledLink to={article.link}>Click for more</StyledLink>
                  </div>
                </GradientContainer>
              </Slide>
            );
          })}
        </Slider>
      </SliderContainer>
    );
  }
  return null;
};

export default Carousel;
