import React from "react";
import { graphql, navigate, Link, PageProps } from "gatsby";
import { Column, Row } from "hedron";
import Layout from "../components/Layout";

import Breadcrumb from "../components/Breadcrumb";
import { PaginateStyles } from "../components/PaginateStyles";
import PageContent from "../components/PageContent";
import PostCover from "../components/PostCover";
import SEO from "../components/seo";
import SideBar from "../components/SideBar";
import { IWordpressPost } from "../types/wordpressPost";
import { IFeaturedArticle } from "../types/featuredArticle";
import HomePageFeatured from "../components/HomePageFeatured";
import { IFluidImageSizes } from "../types/imageSizes";
import ReactPaginate from "react-paginate";
import MainAndSidebarWrapper from "../components/MainAndSidebarWrapper";
import { NewsColumn } from "../components/ContentColumn";
import NewsArchiveLink from "../components/NewsArchiveLink";
import { IJurassicOutpostSettings } from "../types/settings";

interface IHomePage {
  title: string;

  homeHero: {
    heroCarousel: ICarouselItem[];
    heroArticles: IFeaturedArticle[];
  };
}

export interface ICarouselItem {
  coverText?: string;
  coverImage?: {
    localFile: IFluidImageSizes;
  };
  link: string;
}

export interface IWordpressNodeRest {
  id: string;
  date: string;
  slug: string;
  author: string;
  content: { rendered: string };
  featured_media?: number;
  title: { rendered: string };
  excerpt: { rendered: string };
  _embedded: {
    author?: IEmbeddedAuthor[];
  };
}

export interface IEmbeddedAuthor {
  name: string;
  slug: string;
  avatar_urls: {
    24: string;
    48: string;
    96: string;
  };
}

interface ISettings {
  jurassicOutpostSettings: Pick<
    IJurassicOutpostSettings,
    "metadataTags" | "metadataDescription"
  >;
}

interface Props extends PageProps {
  data: {
    allWp: {
      nodes: ISettings[];
    };
    allWpPost: {
      nodes: IWordpressPost[];
    };
    wpPage: IHomePage;
    postsCount: { totalCount: number };
  };
}
const IndexPage: React.FC<Props> = ({ data, location }) => {
  const homePage = data.wpPage;

  const siteOptions = data.allWp.nodes[0].jurassicOutpostSettings;
  const posts = data.allWpPost.nodes;
  if (siteOptions) {
    const siteTags = siteOptions.metadataTags.replace(/(<([^>]+)>)/gi, "");

    return (
      <Layout location={location}>
        <>
          <SEO
            title="Your source for Jurassic Park news & info"
            keywords={siteTags.split(", ")}
            description={siteOptions.metadataDescription}
          />

          <Row>
            <Column sm={12} fluid={true}>
              <HomePageFeatured
                carousel={homePage?.homeHero?.heroCarousel}
                featured={
                  homePage?.homeHero?.heroArticles
                }
              />
            </Column>
          </Row>
          <MainAndSidebarWrapper>
            <Column md={3}>
              <SideBar />
            </Column>
            <NewsColumn md={9}>
              <Breadcrumb title="Latest News" />
              <PageContent>
                <>
                  {posts &&
                    posts.slice(0, 6).map((post) => {
                      const article = post;
                      return (
                        <PostCover
                          id={article.databaseId}
                          key={post.id}
                          author={{
                            name: article.author.node.name,
                            url: article.author.node.url,
                            customAvatar: article.author.node.customAvatar,
                          }}
                          date={article.date}
                          slug={article.slug}
                          title={article.title}
                          content={article.content}
                          excerpt={article.excerpt}
                          featuredImage={article.featuredImage}
                        />
                      );
                    })}
                  <PaginateStyles>
                    <ReactPaginate
                      pageCount={data.postsCount.totalCount / 6}
                      pageRangeDisplayed={3}
                      marginPagesDisplayed={2}
                      initialPage={0}
                      hrefBuilder={(pageIndex) =>
                        pageIndex === 1 ? "/" : `/news/${pageIndex}`
                      }
                      disableInitialCallback={true}
                      onPageChange={(sel) =>
                        navigate(
                          sel.selected === 0 ? "/" : `/news/${sel.selected + 1}`
                        )
                      }
                    />
                  </PaginateStyles>
                  <NewsArchiveLink />
                </>
              </PageContent>
            </NewsColumn>
          </MainAndSidebarWrapper>
        </>
      </Layout>
    );
  } else {
    return null;
  }
};

export default IndexPage;

export const query = graphql`
  query homePageQuery {
    allWp {
      nodes {
        jurassicOutpostSettings {
          metadataTags
          metadataDescription
        }
      }
    }
    allWpPost(sort: { fields: date, order: DESC }, limit: 10) {
      nodes {
        ...getWordpressPostContent
      }
    }
    wpPage(slug: { eq: "home-page" }) {
      title
      homeHero {
        heroCarousel {
          coverText
          coverImage {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, width: 1200)
              }
            }
          }
          link
        }
        heroArticles {
          __typename
          ... on WpPost {
            title
            slug
            featuredImage {
              node {
                localFile {
                  childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED, width: 300, height: 150)
                  }
                }
              }
            }
          }
        }
      }
    }
    postsCount: allWpPost {
      totalCount
    }
  }
`;
