import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image"
import * as React from "react";
import ReactHtmlParser from "react-html-parser";
import "slick-carousel/slick/slick.css";
import styled from "../lib/styled";
import { IFeaturedArticle } from "../types/featuredArticle";

const FeaturedImage = styled.div`
  width: 140px;
  height: auto;
  flex: none;
`;
const FeaturedArticle = styled.div`
  display: flex;
  border: 1px solid ${(props) => props.theme.color.accent};
  background: ${(props) => props.theme.color.darkTwo};
  margin-bottom: 25px;

  &:last-child {
    margin-bottom: 0;
  }

  @media (min-width: 1100px) {
    flex-direction: column;
    ${FeaturedImage} {
      height: 100px;
      width: 100%;
      flex: auto;
      overflow: hidden;
    }
  }
  @media (min-width: 1200px) {
    flex-direction: row;
    ${FeaturedImage} {
      width: 140px;
      overflow: hidden;
      height: auto;
      flex: none;
    }
  }
`;

const FeaturedTitle = styled.h3`
  font-family: "Bebas Neue";
  margin: 0;
  margin-bottom: 10px;
  font-size: 1.4rem;
  line-height: 1;
  color: white;
  text-shadow: 0 0 15px rgba(0, 0, 0, 1);
`;
const ArticleInfo = styled.div`
  padding: 15px;
  @media (min-width: 1200px){
    min-height: 165px;
  }
  
`;

const StyledLink = styled(Link)`
  font-family: "Bebas Neue";
  padding: 6px 10px;
  background: ${(props) => props.theme.color.accentDark};
  color: white;
  font-size: 1.2rem;
`;

const Featured = ({ articles }: { articles: IFeaturedArticle[] }) => {
  if (articles) {
    return (
      <>
        {articles.map((article, i) => {
          const image =
            article.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData;

          return (
            <FeaturedArticle key={i}>
              {image && (
                <FeaturedImage>
                  {image && (
                    <GatsbyImage
                      alt="Featured Image"
                      image={image}
                      style={{ display: "block", height: "100%" }}
                    />
                  )}
                </FeaturedImage>
              )}
              <ArticleInfo>
                <FeaturedTitle>{ReactHtmlParser(article.title)}</FeaturedTitle>
                <StyledLink to={`/${article.slug}`}>Click for more</StyledLink>
              </ArticleInfo>
            </FeaturedArticle>
          );
        })}
      </>
    );
  }
  return null;
};

export default Featured;
