import { Column, Row } from "hedron";
import * as React from "react";
import "slick-carousel/slick/slick.css";
import { ICarouselItem } from "../pages/index";
import { IFeaturedArticle } from "../types/featuredArticle";
import Carousel from "./Carousel";
import Featured from "./FeaturedArticles";
import styled from "styled-components";
import { presets } from "../lib/presets";

interface IProps {
  carousel?: ICarouselItem[];
  featured?: IFeaturedArticle[];
}

const HomePageFeatured = (props: IProps) => {
  return (
    <Row>
      {props.carousel && (
        <CarouselCol sm={12} md={12} lg={8}>
          <Carousel articles={props.carousel} />
        </CarouselCol>
      )}
      {props.featured && (
        <Column
          sm={12}
          md={12}
          lg={4}
          style={{
            paddingBottom: 0,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between"
          }}
        >
          <Featured articles={props.featured} />
        </Column>
      )}
    </Row>
  );
};

const CarouselCol = styled(Column)`
  ${presets.mq.min.desktop} {
    padding-right: 0;
  }
  padding-bottom: 0;
`;

export default HomePageFeatured;
